import { useHead } from '@unhead/vue';
import { useCookie } from 'nuxt/app';
import { computed, onMounted } from 'vue';

import { sessionCookieSetting } from '../src/cookieSettings.ts';
import { useMainStore } from '../store/index.ts';

export type Mode = 'light' | 'dark' | 'automatic';

export default () => {
    const store = useMainStore();
    useHead(() => {
        if (store.darkMode) {
            return {
                bodyAttrs: {
                    'data-theme': 'dark',
                    'data-bs-theme': 'dark',
                },
            };
        } else {
            return {};
        }
    });

    const darkModeCookie = useCookie<boolean>('darkMode', sessionCookieSetting);
    store.darkMode = darkModeCookie.value;

    const isDark = computed({
        get() {
            return store.darkMode;
        },
        set(darkMode) {
            store.darkMode = darkMode;
            darkModeCookie.value = darkMode;
        },
    });

    const getMode = (): Mode => {
        if (!import.meta.client) {
            return 'automatic';
        }

        return localStorage.getItem('mode') as Mode | null || 'automatic';
    };
    const setMode = (mode: Mode): void => {
        if (!import.meta.client) {
            return;
        }

        localStorage.setItem('mode', mode);
    };

    const detectDark = (): boolean => {
        if (!import.meta.client) {
            return false;
        }

        switch (getMode()) {
            case 'light':
                return false;
            case 'dark':
                return true;
            case 'automatic':
            default:
                return window.matchMedia('(prefers-color-scheme: dark)').matches;
        }
    };
    onMounted(() => {
        isDark.value = detectDark();
    });

    return {
        getMode,
        setMode,
        detectDark,
        isDark,
    };
};
